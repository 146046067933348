<template>
  <section>
    <v-container>
      <v-carousel hide-delimiters :cycle="true" :show-arrows="false" class="rounded-lg mx-auto mt-4" style="height: 400px;">
        <v-carousel-item :src="'https://via.placeholder.com/1920x1080.png?text=Team%20Photo%20A%201920x1080'"></v-carousel-item>
        <v-carousel-item :src="'https://via.placeholder.com/1920x1080.png?text=Team%20Photo%20B%201920x1080'"></v-carousel-item>
        <v-carousel-item :src="'https://via.placeholder.com/1920x1080.png?text=Team%20Photo%20C%201920x1080'"></v-carousel-item>
      </v-carousel>
      <div class="mt-12">
        <div>
          <v-row class="hidden-sm-and-down" no-gutters>
            <v-col v-for="(teamMember, index) in teamMembers" :key="index" xl="6" lg="12" md="12" class="d-flex justify-center mb-12">
              <div class="d-flex">
                <div>
                  <cropped-image-viewer :image="teamMember.image.url" :coordinates="teamMember.image.coordinates" :width="300" :height="400" classes="rounded-0 rounded-l-lg elevation-4"></cropped-image-viewer>
                </div>
                <div>
                  <v-card class="team-member-card rounded-0 rounded-r-lg">
                    <v-card-title>
                      <div class="font-weight-bold friendly-4 mt-2">{{ teamMember.publicName }}</div>
                    </v-card-title>
                    <v-card-text>
                      <div class="font-weight-regular friendly-2 mt-1">{{ teamMember.title }}</div>
                      <div class="font-weight-regular friendly-1 mt-1">{{ teamMember.email }}</div>
                      <div class="mt-4">
                        {{ teamMember.about }}
                        <div v-if="teamMember.firstName.toLowerCase() === 'spooky'">
                          <v-img src="https://s3.us-east-1.wasabisys.com/sea2sea/media/Spooky.png" width="200" style="position: absolute; bottom: 0; right: 0;"></v-img>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="hidden-md-and-up" no-gutters>
            <v-col v-for="(teamMember, index) in teamMembers" :key="index" sm="12" class="d-flex justify-center mb-12">
              <div>
                <v-card class="team-member-card rounded-lg">
                  <div class="text-center mb-n2">
                    <cropped-image-viewer :image="teamMember.image.url" :coordinates="teamMember.image.coordinates" :width="300" :height="300" classes="rounded-0 rounded-lg elevation-0 mx-4 mt-4"></cropped-image-viewer>
                  </div>
                  <v-card-title>
                    <div class="font-weight-bold friendly-4 mt-2">{{ teamMember.publicName }}</div>
                  </v-card-title>
                  <v-card-text>
                    <div class="font-weight-regular friendly-2 mt-1">{{ teamMember.title }}</div>
                    <div class="font-weight-regular friendly-1 mt-1">{{ teamMember.email }}</div>
                    <div class="mt-4">
                      <span>{{ teamMember.about }}</span>
                      <div v-if="teamMember.firstName.toLowerCase() === 'spooky'">
                        <v-img src="https://s3.us-east-1.wasabisys.com/sea2sea/media/Spooky.png" width="200" style="position: relative; bottom: 0; float:right;"></v-img>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

    </v-container>
  </section>
</template>

<script>
import CroppedImageViewer from '@/components/image/croppedImageViewer.vue'

export default {
  name: 'Team',
  components: { CroppedImageViewer },
  data: () => ({
    isLoading: false,
    teamMembers: []
  }),
  methods: {
    doLoadTeamMembers () {
      this.isLoading = true

      this.$services.teamMember.doGetAllPublic().then(teamMembers => {
        this.teamMembers = teamMembers
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doFocus () {

    }
  },
  mounted () {
    this.doLoadTeamMembers()
  },
  created () {
    this.doSetDocumentTitle('The Team', true)
  }
}
</script>

<style scoped>
.blurry {
  filter: blur(40px);
  -webkit-filter: blur(40px);
  cursor: pointer !important;
  pointer-events: none !important;
}

.team-member-card {
  height: 400px;
  width: 500px;
}

/* Going to LG */
@media only screen and (max-width: 1904px) {
  .team-member-card {
    height: 400px;
    width: 860px;
  }
}

/* Going to MD */
@media only screen and (max-width: 1264px) {
  .team-member-card {
    height: 400px;
    width: 575px;
  }
}

/* Going to SM */
@media only screen and (max-width: 960px) {
  .team-member-card {
    height: 100%;
    width: 100%;
  }
}

</style>
